import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/Analytics";

type Language = "en" | "ja";
type Data = {
  result: boolean;
  message: string;
  output: string;
} | undefined;

export const gasFetchGet = async (input: string, src: Language, dest: Language): Promise<Data> => {
  try {
    const params = {
      apikey: `${process.env.REACT_APP_GAS_API_KEY}`,
      input: input,
      src: src,
      dest: dest
    };
    const query = new URLSearchParams(params);

    const response = await fetch(`${process.env.REACT_APP_GAS_URL}?${query}`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-recipe",
        module: "utils/GasDoGet.tsx",
        function: "gasFetchGet"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "utils/GasDoGet.tsx",
        function: "gasFetchGet"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-recipe",
        module: "utils/GasDoGet.tsx",
        function: "gasFetchGet"
      });
    }
    return undefined;
  }
}
