import {
  Typography,
  Link
} from "@mui/material";

const listStyle = {
  paddingLeft: 16
};
// ヘルプに表示する内容
export const helpList = [
  {
    summary: "ポイントについて",
    details: (
      <div>
        <Typography variant="caption">
          <ul style={listStyle}><li>
            {"所有ポイントは、毎朝9時を過ぎた後に回復するよ！回復しない場合はページをリロードしてね！"}
          </li><li>
              {`ファイルサイズにかかわらず、1回の解析につき ${Number(process.env.REACT_APP_imageClassificationPoints)} pt が消費されるよ！`}
            </li><li>
              {`所有ポイントは他のAIアプリと連動しているよ！`}
            </li></ul>
        </Typography>
      </div>
    )
  },
  {
    summary: "使用ツール",
    details: (
      <div>
        <Typography variant="caption">
          <ul style={listStyle}><li>
            {"画像解析：Facebook / DETR ResNet-50"}
          </li><li>翻訳：
              <Link
                href="http://translate.google.com/?hl=ja"
                target="_blank"
                rel="noopener noreferrer"
              >
                powered by Google Translate
              </Link>
            </li><li>
              レシピ検索：
              <Link
                href="https://developers.rakuten.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Supported by Rakuten Developers
              </Link>
            </li></ul>
        </Typography>
      </div>
    )
  },
  {
    summary: "プライバシーポリシー",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            {"Google Analyticsについて"}
            <ul style={listStyle}><li>
              {"当サイトではGoogle Analyticsを利用しています。"}
            </li><li>
                {"トラフィックデータの収集のためにCookieを使用しております。トラフィックデータは匿名で収集されており、個人を特定するものではありません。"}
              </li></ul>
          </li></ol>
        </Typography>
      </div>
    )
  },
  {
    summary: "利用規約",
    details: (
      <div>
        <Typography variant="caption">
          <ol style={listStyle}><li>
            {"リンクについて"}
            <ul style={listStyle}><li>
              {"当ブログは基本的にリンクフリーです。リンクを行う場合の許可や連絡は不要です。"}
            </li><li>
                {"ただし、インラインフレームの使用や画像の直リンクは禁止します。"}
              </li></ul>
          </li><li>
              {"免責事項について"}
              <ul style={listStyle}><li>
                {"当サイトの情報や、当サイトのリンクやバナーなどで移動したサイトで提供される情報やサービス等について、損害等の一切の責任を負いません。"}
              </li><li>
                  {"できる限り正確な情報を提供するように努めていますが、正確性や安全性を保証するものではありません。"}
                </li></ul>
            </li><li>
              {"著作権について"}
              <ul style={listStyle}><li>
                {"当サイトで掲載している文章や画像などにつきましては、無断転載することを禁止します。"}
              </li><li>
                  {"当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がある場合、お問い合わせフォームよりご連絡ください。"}
                </li></ul>
            </li></ol>
        </Typography>
      </div>
    )
  }
];
