import { useState, useMemo, useEffect } from "react";
import { logEvent } from "firebase/analytics";
import {
  Tooltip,
  Grid,
  Accordion, AccordionSummary, AccordionDetails,
  Input, Button, IconButton,
  Card, CardHeader, CardContent, CardMedia, CardActions, CardActionArea,
  LinearProgress,
  Typography, Skeleton, Divider, Pagination
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Image as ImageIcon,
  Settings as SettingsIcon,
  ImageSearch as ImageSearchIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import {
  getCurrentUser
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { supabase } from "../supabase/Config";
import { fetchPost } from "../utils/FetchFunctions";
import { rakutenRecipe } from "../utils/FetchRakuten";
import { gasFetchGet } from "../utils/FetchGas";
import { useStateContext } from "../utils/StateContext";
import SettingsDialog from "../components/SettingsDialog";

interface ImageClassificationProps {
  recipeCategories: any;
}

function ImageClassification({
  recipeCategories
}: ImageClassificationProps): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    points,
    setPoints,
    setUpdatedDate
  } = useStateContext();

  // 環境変数から画像解析で消費するポイントを取得
  const imageClassificationPoints = Number(process.env.REACT_APP_imageClassificationPoints);
  // 環境変数から画像ファイルの最大サイズを取得
  const maxImageFileSize = Number(process.env.REACT_APP_maxImageFileSize);
  // 使い方のリスト
  const helpDetailsList = [
    `「画像を選択」ボタンを押して、食材の画像を選択してね！ファイルサイズは${maxImageFileSize}MBまでだよ！`,
    "正しい画像が選択されているか確認してね！",
    "「レシピ検索」ボタンを押してね！",
    "レシピ検索が終わると、「レシピ検索」ボタンの下にレシピ一覧が表示されるよ！",
    "気になるレシピがあったら、そのレシピをクリックしてね！",
  ];
  // 1ページあたりのレシピ数
  const recipesPerPage = Number(process.env.REACT_APP_recipesPerPage);

  // 使い方を展開するか
  const [helpAccordion, setHelpAccordion] = useState<boolean>(false);
  // 詳細設定の表示するか
  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
  //  画像分類結果の類似度の最小閾値
  const [minImageScore, setMinImageScore] = useState<number>(Number(process.env.REACT_APP_minImageScore));
  // レシピ検索する食材数の最大閾値
  const [maxOutputCount, setMaxOutputCount] = useState<number>(Number(process.env.REACT_APP_maxOutputCount));
  // 画像ファイル
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  // 画像解析の日本語名のリスト
  const [outputList, setOutputList] = useState<string[]>([]);
  // レシピ一覧
  const [recipes, setRecipes] = useState<any[]>([]);
  // レシピ取得の進捗
  const [recipesProgress, setRecipesProgress] = useState<string>("0/0");
  // 現在のページ番号
  const [page, setPage] = useState<number>(1);
  // 画像からレシピ検索の進捗
  const [progress, setProgress] = useState<number>(0);

  // 画像解析の進捗に表示するメッセージのリスト
  const progressMessageList = useMemo(() => {
    return [
      "",
      "画像をストレージに保存中...",
      "画像解析中...",
      "画像をストレージから削除中...",
      "翻訳中...",
      `レシピ検索中... (${recipesProgress})`,
      "レシピ検索完了！"
    ];
  }, [recipesProgress]);

  // アップロードされた画像を画面に表示するためのURL
  const imageFileUrl = useMemo(() => {
    if (imageFile) {
      return window.URL.createObjectURL(imageFile);
    } else {
      return "";
    }
  }, [imageFile]);

  // 画像解析後のポイント
  const newPoints = useMemo(() => {
    return points - imageClassificationPoints;
  }, [points, imageClassificationPoints]);

  useEffect(() => {
    const newMessage = progressMessageList[progress];
    const messageSeverity = (progress === progressMessageList.length - 1) ? "success" : "info";
    if (newMessage) {
      createMessage(newMessage, messageSeverity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, recipesProgress])

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProgress(0);
    const files = e.target.files;
    if (files && files[0]) {
      const newFile = files[0];
      // 最大ファイルサイズ
      const maxFileSize = maxImageFileSize * 1024 * 1024;
      // 最大ファイルサイズを超えていないか確認
      if (newFile.size > maxFileSize) {
        createMessage(`${maxImageFileSize}MB以下のファイルを選択してください。`, "error");
        setImageFile(undefined);
      } else {
        setImageFile(newFile);
      }
    } else {
      setImageFile(undefined);
    }
  };

  const start = async () => {
    setIsRunning(true);
    setProgress(0);
    logEvent(analytics, "レシピ検索の「検索」ボタン押下", {
      app: "maitake-recipe",
      module: "pages/ImageClassification.tsx",
      function: "start"
    });

    // 現在サインインしているユーザを取得
    const currentUser = getCurrentUser();
    if (!currentUser) {
      createMessage("サインインしていないよ。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 解析結果をリセット
    setOutputList([]);
    setRecipes([]);
    setRecipesProgress("0/0");

    // サインインしているユーザのuidを取得
    const uid = currentUser.uid;

    // 画像をSupabaseのStorageにアップロード
    setProgress(1);
    //createMessage("画像をストレージに保存中...", "info");
    try {
      createMessage(uid, "info");
      const insertResult = await insertImage(uid);
      if (!insertResult) {
        setProgress(4);
        //createMessage("画像をストレージから削除中...", "warning");
        await deleteImage(uid);

        createMessage("画像アップロード失敗。", "error");
        setProgress(0);
        setIsRunning(false);
        return;
      }
    } catch {
      logEvent(analytics, "画像をストレージに保存できませんでした。", {
        app: "maitake-recipe",
        module: "pages/ImageClassification.tsx",
        function: "start"
      });
      createMessage(`画像をストレージに保存できませんでした。もう一度「レシピ検索」ボタンを押してください。`, "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 画像解析
    setProgress(2);
    //createMessage("画像解析中...", "info");
    const data = await fetchPost("imageClassification", uid, "");

    // 画像をSupabaseのStorageから削除
    setProgress(3);
    //createMessage("画像をストレージから削除中...", "info");
    await deleteImage(uid);

    if (data === undefined) {
      createMessage("送信失敗。", "error");
      setProgress(0);
      setIsRunning(false);
      return;
    }
    setPoints(data.points);
    setUpdatedDate(data.updatedDate);
    createMessage(data.message, (data.result ? "success" : "error"));

    if (!data.result) {
      setProgress(0);
      return;
    }

    const newOutput = Array.from(
      new Set(
        data.output
          .filter((item: any) => item.score >= minImageScore)
          .map((item: any) => item.label)
      )
    ).join('. ');
    setOutputList(newOutput.split(". "));
    if (newOutput.length === 0) {
      createMessage("画像から食材が見つかりませんでした。", "warning");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    // 画像解析結果を英語から日本語に翻訳
    setProgress(4);
    //createMessage("翻訳中...", "info");
    const newOutputName = await translate(newOutput);
    const newOutputList = newOutputName.split("。");
    setOutputList(newOutputList);

    // レシピ検索
    setProgress(5);
    //createMessage("レシピ検索中...", "info");
    const searchCategoryResult = await searchCategory(newOutputList);
    if (!searchCategoryResult) {
      createMessage("レシピが見つかりませんでした。", "warning");
      setProgress(0);
      setIsRunning(false);
      return;
    }

    setProgress(6);
    //createMessage("レシピ検索完了！", "success");
    setIsRunning(false);
  };

  const insertImage = async (uid: string): Promise<boolean> => {
    if (!imageFile) {
      return false;
    }
    // 画像をSupabaseのStorageにアップロード
    const { error } = await supabase.storage
      .from("maitake-ai")
      .upload(uid, imageFile, {
        upsert: true,
      });

    if (error) {
      return false;
    }
    return true;
  };

  const deleteImage = async (uid: string): Promise<void> => {
    // 画像をSupabaseのStorageから削除
    await supabase.storage
      .from("maitake-ai")
      .remove([uid]);
  };

  const translate = async (text: string): Promise<string> => {
    // 画像解析結果を英語から日本語に翻訳
    const data = await gasFetchGet(text, "en", "ja");

    if (data === undefined) {
      createMessage("翻訳失敗。", "error");
      return "";
    }
    createMessage(data.message, (data.result ? "success" : "error"));
    return data.output;
  };

  // 画像解析結果に含まれるカテゴリを検索
  const searchCategory = async (newOutputList: string[]) => {
    // 片仮名を平仮名に変換する関数
    const kataToHira = (str: string) => {
      return str.replace(/[\u30a1-\u30f6]/g, (match) => {
        return String.fromCharCode(match.charCodeAt(0) - 0x60);
      });
    };
    // 平仮名を片仮名に変換する関数
    const hiraToKana = (str: string) => {
      return str.replace(/[\u3041-\u3096]/g, (match) => {
        return String.fromCharCode(match.charCodeAt(0) + 0x60);
      });
    };

    const slicedOutputList = newOutputList.slice(0, maxOutputCount);

    const hiraOutputList = slicedOutputList.map(kataToHira);
    const kataOutputList = slicedOutputList.map(hiraToKana);
    const extendedOutputList = Array.from(new Set([...slicedOutputList, ...hiraOutputList, ...kataOutputList]));

    const matchedRecipeCategories = recipeCategories.filter((category: any) =>
      extendedOutputList.some(outputItem => category.categoryName.includes(outputItem))
    );
    if (matchedRecipeCategories.length === 0) {
      return false;
    }

    const matchedOutputList = extendedOutputList.filter(outputItem =>
      matchedRecipeCategories.some((category: any) => category.categoryName.includes(outputItem))
    );
    setOutputList(matchedOutputList);

    // 各カテゴリごとにレシピを検索
    const newRecipes: any = [];
    let newRecipeProgress = 0;
    for (const category of matchedRecipeCategories) {
      newRecipeProgress++;
      const recipesForCategory = await rakutenRecipe(category.categoryId);
      if (!recipesForCategory) {
        continue;
      }
      newRecipes.push(...recipesForCategory);
      setRecipesProgress(`${newRecipeProgress}/${matchedRecipeCategories.length}`);
      // 1秒待機する
      await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // スコアを追加し、高い順に並び替える
    newRecipes.forEach((recipe: any) => {
      const materialList = recipe.recipeMaterial;
      // スコア計算に使用済みの材料
      const usedOutputItems: string[] = [];
      let score = 0;
      extendedOutputList.forEach(outputItem => {
        materialList.forEach((material: any) => {
          if (!usedOutputItems.includes(outputItem) && material.includes(outputItem)) {
            usedOutputItems.push(outputItem);
            score++;
          }
        });
      });
      recipe.score = score;
    });

    // recipeIdで重複している要素を削除
    const uniqueRecipes = Array.from(new Set(
      newRecipes.map((recipe: any) => recipe.recipeId)
    )).map(recipeId => {
      return newRecipes.find((recipe: any) => recipe.recipeId === recipeId);
    });

    // scoreが高い順に並び替える
    uniqueRecipes.sort((a: any, b: any) => b.score - a.score);
    setRecipes(uniqueRecipes);

    return true;
  };

  const startIndex = useMemo(() =>
    (page - 1) * recipesPerPage
    , [page, recipesPerPage]);
  const endIndex = useMemo(() =>
    Math.min(startIndex + recipesPerPage, recipes.length)
    , [startIndex, recipes, recipesPerPage]);
  const visibleRecipes = useMemo(() =>
    recipes.slice(startIndex, endIndex)
    , [recipes, startIndex, endIndex]);

  return (
    <div>
      <Typography
        sx={{ mt: 2 }}
      >
        {`消費ポイント：${imageClassificationPoints} pt`}
      </Typography>

      <Accordion
        expanded={helpAccordion}
        onChange={() => setHelpAccordion(!helpAccordion)}
        sx={{
          mt: 2,
          textAlign: "start"
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {"使い方"}
        </AccordionSummary>
        <AccordionDetails>
          <ol>
            {helpDetailsList.map((helpDetails, index) => (
              <li key={index}>
                {helpDetails}
              </li>
            ))}
          </ol>
        </AccordionDetails>
      </Accordion>

      <Grid container
        spacing={2}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12}
          sx={{ mt: 2 }}
        >
          <Card>
            <CardContent>
              <label htmlFor="imageFile">
                <Input
                  id="imageFile"
                  type="file"
                  onChange={onChangeFile}
                  required
                  disabled={
                    isRunning
                    || (!isSignIn)
                  }
                  fullWidth
                  disableUnderline
                  inputProps={{
                    accept: "image/*",
                    style: { display: "none" }
                  }}
                />
                <Button
                  component="span"
                  disabled={isRunning
                    || (!isSignIn)
                  }
                  startIcon={<ImageIcon />}
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  {"画像を選択"}
                </Button>
              </label>
            </CardContent>

            {imageFileUrl ?
              <CardMedia
                component="img"
                image={imageFileUrl}
                sx={{
                  height: "100%",
                  objectFit: "contain"
                }}
              />
              :
              <Skeleton
                variant="rectangular"
                height={240}
                animation={false}
                sx={{ mt: 2 }}
              />
            }

            <CardActions sx={{ justifyContent: "center" }}>
              <Tooltip arrow
                title="詳細設定"
              >
                <IconButton
                  onClick={() => setSettingsDialogOpen(true)}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Button
                onClick={start}
                disabled={isRunning
                  || (!isSignIn)
                  || (!imageFile)
                  || (newPoints < 0)
                }
                startIcon={<ImageSearchIcon />}
                fullWidth
                variant="contained"
                size="large"
                color="primary"
              >
                {`レシピ検索`}
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <LinearProgress variant="determinate"
            value={(progress / (progressMessageList.length - 1)) * 100}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            {progressMessageList[progress]}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ArrowDownwardIcon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">
            {outputList ?
              outputList.join(', ')
              :
              <Skeleton
                variant="rectangular"
                animation={false}
              />
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}
          sx={{
            textAlign: "right",
          }}
        >
          <Typography>
            {recipes.length > 0 ?
              `${startIndex + 1} ~ ${endIndex} 件 / ${recipes.length} 件中`
              :
              "検索結果なし"
            }
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Pagination
            count={Math.ceil(recipes.length / recipesPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </Grid>

        {visibleRecipes.map((recipe, index) => (
          <Grid item key={index} xs={12}>
            <Card>
              <CardActionArea
                href={recipe.recipeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardHeader
                  title={recipe.recipeTitle}
                  subheader={`score: ${recipe.score}`}
                />

                <CardMedia
                  component="img"
                  image={recipe.foodImageUrl}
                  alt={recipe.recipeTitle}
                />

                <CardContent
                  sx={{
                    textAlign: "left"
                  }}
                >
                  <Typography variant="body1">
                    {recipe.recipeMaterial.join(", ")}
                  </Typography>
                  <Divider
                    sx={{ my: 1 }}
                  />

                  <Typography variant="caption">
                    {recipe.recipeDescription}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>

      <SettingsDialog
        settingsDialogOpen={settingsDialogOpen}
        setSettingsDialogOpen={setSettingsDialogOpen}
        minImageScore={minImageScore}
        setMinImageScore={setMinImageScore}
        maxOutputCount={maxOutputCount}
        setMaxOutputCount={setMaxOutputCount}
      />
    </div>
  );
}

export default ImageClassification;
