import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/Analytics";

type RecipeCategory = {
  categoryId: string;
  categoryName: string;
};

export const rakutenRecipeCategory = async (): Promise<RecipeCategory[] | undefined> => {
  try {
    const response = await fetch(`https://app.rakuten.co.jp/services/api/Recipe/CategoryList/20170426?applicationId=${process.env.REACT_APP_applicationId}`);
    if (response.ok) {
      const data = await response.json();
      return transformCategories(data.result);
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipeCategory"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipeCategory"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipeCategory"
      });
    }
    return undefined;
  }
}

export const rakutenRecipe = async (categoryId: string): Promise<RecipeCategory[] | undefined> => {
  try {
    const response = await fetch(`https://app.rakuten.co.jp/services/api/Recipe/CategoryRanking/20170426?applicationId=${process.env.REACT_APP_applicationId}&categoryId=${categoryId}`);
    if (response.ok) {
      const data = await response.json();
      return data.result;
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipe"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipe"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-recipe",
        module: "utils/FetchRakuten.tsx",
        function: "rakutenRecipe"
      });
    }
    return undefined;
  }
}

const transformCategories = (result: any) => {
  const transformedCategories: RecipeCategory[] = [];

  // large categories
  result.large.forEach((cat: any) => {
    transformedCategories.push({
      categoryId: `${cat.categoryId}`,
      categoryName: cat.categoryName
    });
  });

  // medium categories
  result.medium.forEach((cat: any) => {
    transformedCategories.push({
      categoryId: `${cat.parentCategoryId}-${cat.categoryId}`,
      categoryName: cat.categoryName
    });
  });

  // small categories
  result.small.forEach((cat: any) => {
    const parentMediumCategory = result.medium.find((medCat: any) => String(medCat.categoryId) === String(cat.parentCategoryId));
    if (parentMediumCategory) {
      transformedCategories.push({
        categoryId: `${parentMediumCategory.parentCategoryId}-${cat.parentCategoryId}-${cat.categoryId}`,
        categoryName: cat.categoryName
      });
    }
  });

  return transformedCategories;
};
