import { logEvent } from "firebase/analytics";
import {
  Grid,
  Typography,
  Button
} from "@mui/material";
import {
  Google as GoogleIcon
} from "@mui/icons-material";
import {
  signInGoogle
} from "../firebase/Authentication-Google";
import { analytics } from "../firebase/Analytics";
import { useStateContext } from "../utils/StateContext";

function SignIn(): JSX.Element {
  const {
    createMessage,
    isRunning,
    setIsRunning,
    isSignIn,
    setIsSignIn
  } = useStateContext();

  const signIn = async (): Promise<void> => {
    setIsRunning(true);
    createMessage("サインイン中...", "info");
    logEvent(analytics, "「サインイン」ボタン押下", {
      app: "maitake-recipe",
      module: "components/SignIn.tsx",
      function: "signIn"
    });

    // Googleアカウントでサインインする
    const authResult = await signInGoogle();
    setIsSignIn(authResult);

    if (!authResult) {
      createMessage("サインイン失敗。", "error");
      setIsRunning(false);
      return;
    }

    createMessage("サインイン成功！", "success");
    setIsRunning(false);
  }

  return (
    <Grid container
      spacing={2}
      sx={{
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Grid item xs={12}
        sx={{ mt: 8 }}
      >
        <Typography>
          {`Googleアカウントを使用して`}
        </Typography>
        <Typography>
          {`サインインしてね！`}
        </Typography>
      </Grid>

      <Grid item xs={12}
        sx={{ mt: 12 }}
      >
        <Button
          onClick={signIn}
          disabled={isRunning
            || (isSignIn)
          }
          startIcon={<GoogleIcon />}
          variant="contained"
          size="large"
          color="primary"
        >
          {`サインイン`}
        </Button>
      </Grid>
    </Grid>
  );
}

export default SignIn;
