import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/Analytics";

type Url = "readPoints" | "imageClassification" | "textClassification";
type GetData = {
  result: boolean;
  message: string;
  points: number;
  updatedDate: string;
} | undefined;
type PostData = {
  result: boolean;
  message: string;
  points: number;
  updatedDate: string;
  output: any;
} | undefined;

export const fetchGet = async (url: Url, uid: string): Promise<GetData> => {
  try {
    const response = await fetch(`/api/${url}?uid=${uid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchGet"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchGet"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchGet"
      });
    }
    return undefined;
  }
}

export const fetchPost = async (url: Url, uid: string, input: string): Promise<PostData> => {
  try {
    const response = await fetch(`/api/${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        uid: uid,
        input: input
      })
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      logEvent(analytics, `${response.status}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
      return undefined;
    }
  } catch (error) {
    if (error instanceof Error) {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
    } else {
      logEvent(analytics, `${error}`, {
        app: "maitake-recipe",
        module: "utils/FetchFunctions.tsx",
        function: "fetchPost"
      });
    }
    return undefined;
  }
}
