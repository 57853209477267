import { logEvent } from "firebase/analytics";
import {
  User,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged
} from "firebase/auth";
import { analytics } from "../firebase/Analytics";
import app from "./Config";

export type AuthUser = User | undefined;

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// ポップアップを表示して、Googleでサインインする
export const signInGoogle = async (): Promise<boolean> => {
  return await signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error("credential is null");
      }
      return true;
    }).catch((error) => {
      logEvent(analytics, `${error.message}`, {
        app: "maitake-recipe",
        module: "firebase/Authentication-Google.tsx",
        function: "signInGoogle"
      });
      return false;
    });
}

// サインアウトする
export const signOutGoogle = async (): Promise<boolean> => {
  return await signOut(auth).then(() => {
    return true;
  }).catch((error) => {
    logEvent(analytics, `${error.message}`, {
      app: "maitake-recipe",
      module: "firebase/Authentication-Google.tsx",
      function: "signOutGoogle"
    });
    return false;
  });
}

// Googleでサインインしているか確認する
export const onAuthStateChangedGoogle = (): Promise<boolean> => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}

// 現在のサインインしているアカウントを確認する
export const getCurrentUser = (): AuthUser => {
  const user = auth.currentUser;
  if (!user) {
    return undefined;
  }
  return user;
}
