import {
  Tooltip, Stack,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Slider,
  IconButton,
  Typography,
  Divider
} from "@mui/material";
import {
  Close as CloseIcon
} from "@mui/icons-material";

interface SettingsDialogProps {
  settingsDialogOpen: boolean;
  setSettingsDialogOpen: (settingsDialogOpen: boolean) => void;
  minImageScore: number;
  setMinImageScore: (minImageScore: number) => void;
  maxOutputCount: number;
  setMaxOutputCount: (maxOutputCount: number) => void;
}

function SettingsDialog({
  settingsDialogOpen,
  setSettingsDialogOpen,
  minImageScore,
  setMinImageScore,
  maxOutputCount,
  setMaxOutputCount
}: SettingsDialogProps): JSX.Element {
  return (
    <Dialog
      open={settingsDialogOpen}
      onClose={() => setSettingsDialogOpen(false)}
    >
      <DialogTitle>
        {"詳細設定"}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="body1">
            {"画像解析の類似度の最小閾値"}
          </Typography>

          <Slider
            value={minImageScore}
            onChange={(event, value) => event && setMinImageScore(value as number)}
            valueLabelDisplay="on"
            step={0.1}
            marks
            min={0}
            max={1}
          />

          <Divider />

          <Typography variant="body1">
            {"レシピ検索する食材数の最大閾値"}
          </Typography>

          <Slider
            value={maxOutputCount}
            onChange={(event, value) => event && setMaxOutputCount(value as number)}
            valueLabelDisplay="on"
            step={1}
            marks
            min={1}
            max={20}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Tooltip arrow
          title="閉じる"
        >
          <IconButton
            onClick={() => setSettingsDialogOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsDialog;
